/*Fonts */
@font-face {
  font-family: 'LogoFont';
  src: url('./Fonts/Rosewell.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}
@viewport {
  min-zoom: 100%;
  max-zoom: 200%;
}

body,
html,
.App {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  height: 100%;
  touch-action: none;
  width: 100%;
}
#root {
  height: 100%;
  width: 100%;
}

/*smallScreens*/
.small-screen-div {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: #0d1c28;
  justify-content: center;
}
.small-screen-text {
  position: relative;
  display: flex;
  justify-content: center;
  width: 70%;
  height: 60%;
  background-color: white;
  border-radius: 2%;
  flex-direction: column;
  align-items: center;
  font-size: xx-large;
  font-weight: bold;
  margin: auto;
}
.display-sign {
  width: 40%;
  height: 50%;
}
.circle-small-screen {
  width: 15vh;
  height: 15vh;
  border-radius: 50%;
  border: 1px solid #000;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  position: absolute;
  top: -15%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header-text-small-screen {
  font-size: 3vh;
  text-wrap: wrap;
  text-align: center;
  margin-top: 6vh;
  margin: 2vh;
}
.text-small-screen {
  font-size: 2.5vh;
  text-wrap: wrap;
  text-align: center;
  margin: 1vh;
  font-weight: 100;
}
.splash {
  width: 100%;
  height: 100vh;
  background-color: #0d1c28;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.splash-text {
  color: white;
  margin-top: 10vh;
}
/* LOGIN SCREEN */
.error-message {
  color: #fe1a79;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 90%;
  font-size: smaller;
  margin-top: 10px;
}
.error-message-modal {
  color: #fe1a79;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 90%;
  font-size: smaller;
  margin-top: 3px;
  margin-bottom: 3px;
}
.error-message-code {
  color: #fe1a79;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 90%;
  margin-top: 1vh;
  font-size: normal;
}
.conditions-modal {
  margin-left: 2%;
  margin-right: 2%;
  width: 66%;
  height: 100%;
  border-radius: 10px;
  background-color: white;
  display: flex;
  flex-direction: column;
}
.terms-conditions-text {
  margin: 4vh;
  height: 75%;
  border: 1px solid black;
  color: #000;
  overflow: auto;
}
.login-screen {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  overflow: auto;
  justify-content: center;
}
.image-login {
  width: 64%;
  height: 100%;
  background-image: url('./images/background.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden;
  z-index: 0;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
}
.login-left-part {
  width: 70%;
  height: 100%;
  background-color: #0d1c28;
  color: white;
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
}
input {
  padding: 10px;
}
input::placeholder {
  color: #999; /* Change the color of the placeholder text */
  padding-left: 10px; /* Apply left padding to the placeholder text */
}

.logo-group-login {
  width: 60%;
  height: 40vh;
  margin-left: 20vh;
  font-family: 'LogoFont', sans-serif;
  font-weight: 400;
  font-size: 80px;
  display: flex;
  align-items: center;
}
.read-more-button {
  width: 17%;

  height: 5vh;
  border-radius: 2vh;
  color: white;
  background-color: gray;
}
.login-container {
  display: flex;
  margin: auto;
  height: 100%;
  width :36%;
  color: black;
  background-color: white;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 2;
}

.login-container-email-sent {
  justify-content: flex-start;
  display: flex;
  width: 30%;
  height: 100%;
  color: black;
  background-color: white;
  flex-direction: column;
}

.login-container-recovery {
  display: flex;
  width: 30%;
  height: 100%;
  color: black;
  background-color: white;
  flex-direction: column;
}
.google-button {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 50px;
}

.login-title {
  margin-bottom: 2vh;
  display: flex;
  justify-content: center;
}
.login-box {
  display: flex;
  flex-direction: column;
}
.login-box-mail-sent {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  padding: 30px;
}
.form-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.input-login {
  border-radius: 1vh;
  border-color: #eeeeee;
  border-width: 1px;
  height: 100%;
  opacity: 50%;
  width: 100%;
}
.space-email-sent {
  margin: 40px;
}
.language-block {
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
.login-header {
  display: flex;
  width: 80%;
  color: #504eca;
  font-weight: bolder;
  font-size: x-large;
}

.login-text {
  margin-top: 2vh;
  margin-bottom: 4vh;
  margin-left: 2vh;
  display: flex;
  width: 80%;
  font-size: larger;
}
.login-button {
  width: 430px;
  margin-left: 2vh;
  height: 5vh;
  border-radius: 1vh;
  color: white;
  background-color: black;
}

.forgot-my-password {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  text-decoration-line: underline;
  opacity: 40%;
  cursor: pointer;
}
.not-have-an-account {
  width: 100%;
  display: flex;
  margin-top: 10px;
  justify-content: flex-start;
  opacity: 40%;
  text-decoration: underline;
  cursor: pointer;
}
.start-with-google {
  width: 80%;
  display: flex;
  margin: 2vh;
  justify-content: center;
  opacity: 40%;
  margin-bottom: 1vh;
  margin-top: 15vh;
}
.start-with-google-signup {
  width: 100%;
  display: flex;
  justify-content: left;
  opacity: 40%;
  height: 100%;
  align-items: center;
  text-decoration: underline;
  cursor: pointer;
}

.login-with-google-div {
  width: 100%;
  margin: 2vh;
  display: flex;
  justify-content: center;
  margin-top: 0vh;
}
.login-button-with-google {
  width: 400px;
  margin: 2vh;
  height: 5vh;
  border-radius: 2vh;
  border-width: 1px;
  background-color: white;
  align-self: center;
  margin-top: 0;
  display: flex;
  border-color: #e2e8f0;
  justify-content: center;
  align-items: center;
}
.google-logo {
  width: 2vh;
}
.form-login {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
}
.input-div-login-text {
  margin: 0.5%;
  display: flex;
  width: 400px;
  height: 3vh;
  flex-direction: column;
  justify-content: center;
}

.input-div {
  margin: 20px;
  width: 400px;
  display: flex;

  flex-direction: column;
}
.input-div-signup {
  margin: 5px;
  width: 400px;
  display: flex;
  flex-direction: column;
}

.input-div-under {
  width: 400px;
  display: flex;
}
.signup-button {
  width: 400px;
  margin-left: 2vh;
  height: 5vh;
  border-radius: 1vh;
  color: white;

  background-color: black;
  margin: 20px;
}
.chat-block {
  display: flex;
  text-align: justify;
  flex-direction: column;
}
.links {
  display: flex;
  flex-direction: row;
  color: #504eca;
  font-weight: bold;
}
.mobile {
  background-image: url('./images/mobile-screen.jpeg'); /* Replace with the path to your image */
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100vh;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #2db7db;
  overflow: auto;
}
.tablet {
  background-image: url('./images/tablet-screen.jpeg'); /* Replace with the path to your image */
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100vh;
  background-color: #2db7db;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.login-icon-back {
  height: 32px;
  width: 32px;
  cursor: pointer;
  margin-bottom: 20px;
}
.user-questions-in-chat {
  display: flex;
  flex-direction: row;
  align-items: center;
}
button {
  cursor: pointer;
}

.error-google {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  color: #fe1a79;
}

/*Terms and conditions*/
.terms-and-conditions {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  z-index: 999;
}
.button-block-delete {
  display: flex;
  flex-direction: row;
  margin: auto;

  margin: 4vh;
}
.space {
  width: 5%;
}
.terms-and-conditions-text {
  margin-left: 4vh;
  margin-top: 4vh;
  font-weight: bold;
}
/*Layout*/
.logo-corner {
  width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;

  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  position: relative;
}

.menu-block {
  display: flex;
  width: 50%;
  align-items: center;
  font-size: 1vh;
}
.exit-block {
  width: 49%;
  flex-direction: row-reverse;
  align-items: center;
  display: flex;
}

.header {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
}

.row-layout {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.header-div {
  height: 58px;
  width: 100%;
  display: flex;
  flex-direction: row;
  box-shadow: 0px 8px 8px -2px rgba(0, 0, 0, 0.1);
  font-size: small;
}

.lang-selector {
  display: flex;
  justify-content: center;
  cursor: pointer;
  align-items: center;
}
.space-menu {
  width: 30px;
}
.logout-icon {
  font-size: 16px;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-right: 5px;
}

.main-div {
  width: 100%;
  height: 100%;
  position: absolute;
}
.alert-box {
  position: absolute;
  top: 0;
  right: 0;

  z-index: 4;
}
.alert-header {
  max-width: 300px;
}
.alert-header-text {
  font-weight: bold;
}
.right-part-down {
  width: 100%;
  height: 100%;
  color: black;
}
.new-project {
  min-width: 300px;
}

.initial-handler {
  margin-top: -22px;
  margin-right: 10px;
  border: none;

  color: #eee;
}
.chat-handler {
  margin-top: -22px;
  margin-left: 10px;
  background-color: #eee !important;
  color: #eee;
  border: none;
}
.mother-handler {
  margin-top: -22px;
  margin-left: 10px;
  border-radius: 3px;
  border: none;
  color: #eee;
  background-color: #eee !important;
}

.react-flow .react-flow__node {
  height: 45px;
  width: 122px;
  justify-content: center;
  align-items: center;
  display: flex;
}
.react-flow__handle {
  width: 4px !important;
  height: 4px !important;
  min-width: 3px !important;
  min-height: 3px !important;
}
.header-text-node {
  font-size: 8px;
  font-weight: bolder;
  height: 20px;
  background-color: #504eca;
  display: flex;
  align-items: center;
  padding-left: 15px;
  padding-right: 5px;
  text-align: justify;
  overflow: hidden;
}
.video-integrated {
  border: 1px solid black;
  height: 300px;
  width: 90%;
}
.react-flow__panel.bottom {
  bottom: 40px;
  right: 0;
}
.wrapper {
  flex-grow: 1;
  height: 100%;
}

.text-updater-node {
  height: 65px;
  width: 122px;

  border-radius: 8px;
  color: #ffffff;
  overflow: hidden;
}
.text-node {
  font-size: 7px;
  font-weight: lighter;
  display: flex;
  padding-left: 5px;
  margin-top: 3px;
  margin-right: 5px;
  text-align: justify;
  text-justify: inter-word;
  word-wrap: break-word;
}

.text-updater-node label {
  display: block;
  color: #777;
  font-size: 12px;
}
/*Chat*/

.chat-div {
  position: absolute;
  top: 0%;
  left: 0;
  width: 100%;

  background-color: rgba(255, 255, 255);
  display: flex;
  flex-direction: column;
  z-index: 2;
  height: 100%;
}
.modal-div {
  position: absolute;
  top: -5%;
  left: 0;
  width: 100%;
  background-color: rgba(116, 116, 116, 0.8);

  display: flex;
  flex-direction: column;
  color: #000;
  z-index: 999;
  height: calc(100% + 5%);
}
.header-chat {
  width: 95%;
  height: 10%;

  display: flex;
  align-items: center;
  padding-left: 5%;
}
.footer-logo{
  position: fixed;
}
.chat-icon-back {
  width: 30px;
  height: 30px;
  cursor: pointer;
}
.my-project-name {
  color: #504eca;
  margin-left: 2vh;
}
.chat-main-block {
  height: 90%;
  width: 100%;
  display: flex;
  flex-direction: row;
}
.chat-left-block {
  width: 50%;
  border-radius: 10px;
  height: 90%;
  background-color: #f4f4f8;
  display: flex;
  flex-direction: column;
  margin-left: 5%;
  margin-right: 3%;
  align-items: center;
  overflow: auto;

  /* Styles for Firefox */
  scrollbar-width: thin;

  /* Styles for Webkit Browsers */
  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: #504eca;
  }

  &::-webkit-scrollbar-thumb {
    background: lightgray;
    border-radius: 1px;
  }

  &::-webkit-scrollbar-thumb:hover {
  }
}
.sources-div {
  width: 400px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.name-source {
  width: 60%;
  padding: 10px;
  flex-wrap: wrap;
  display: flex;
  word-wrap: break-word;
  overflow: hidden;
  font-size: small;
  flex-direction: column;
}
.name-source-header {
  font-weight: bold;
  margin: 10px;
}
.button-div {
  display: flex;
  flex-direction: column;
  width: 35%;
}
.items-source {
  display: flex;
  flex-direction: row;
  width: 90%;
  align-items: center;
  height: 100px;
  overflow: hidden;
}

.chat-right-block {
  background-color: #f4f4f8;
  width: 40%;
}
.chat-flow {
  display: flex;
  flex-direction: column;
  margin-top: 10%;
  height: 80%;
  width: 90%;
  margin: 2%;
  overflow: auto;
}
.ql-container {
  background-color: white;
  border: none !important;
  height: 90% !important;
}
.quill {
  height: 90%;
}
.ql-editor {
  height: 100%;
}
.ql-toolbar {
  border: none !important;
  border-color: #f2f4f7;
}
.editor-main-div {
  height: 90%;
  padding: 2%;
}

.input-chat {
  width: 90%;
}
.loading-screen {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 100%;
}
/*PDF*/
.pdf-viewer-screen {
  width: 100%;
  height: 95%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  border-radius: 10px;
}
.button-preview {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.pages-input {
  width: 20px;
  margin: 10px;
  font-size: 12px;
  padding: 5px;
}
.page-numbers {
  display: flex;
  width: 100%;
  justify-content: center;
}
.pdf-doc {
  display: flex;
  margin: auto;
  flex: 1;
}
.rpv-default-layout-container {
  border: none !important;
  overflow: hidden !important;
}

.download-pdf {
  display: flex;
  flex-direction: column;
  margin: 20px;
  width: 100px;
  align-items: center;
  font-size: xx-small;
}
/*New project*/
.label-new-project {
  width: 100%;
  margin-top: 10px;
  font-weight: bolder;
  font-size: small;
}
.horizontal-block-new-project {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.horizontal-block-new-source {
  display: flex;
  flex-direction: row;
}
.form-new-source {
  width: 90%;
  height: 98%;
  margin-top: 2%;
}
.space-horizontal {
  width: 40px;
}
.name-block-new-source {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.button-new-block {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}
.upload-drug-n-drop-div {
  border: 1px dashed #f0efef;
  border-radius: 10px;
  text-align: center;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  overflow: auto;

  justify-content: center;
}

.input-file-div {
  margin-left: 5%;
  min-width: 300px;
}
.warning-div {
  max-width: 300px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

.file-properties-main {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.conditions-text {
  text-align: justify;
  overflow: auto;
  height: 50%;
}
.file-view {
  width: 200px;
  height: 300px;
  background-color: #b5e6f2;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #686565;
}
.div-conditions {
  font-weight: bolder;
  margin-left: 20px;
  margin-bottom: 15px;
  margin-top: 15px;
}
.recycle-bin-view {
  width: 80px;
  height: 80px;
  margin-right: 20px;
  border-radius: 50%;
  background-color: #2db7db;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #686565;
}

.file-download-area {
  display: flex;
  flex-direction: row;
  margin: 5%;
  width: 90%;
  height: 15%;
}
.react-flow__edge {
  z-index: 10; /* Adjust this value as needed */
}
.file-sign {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100px;
  height: 100%;
  position: relative;
}
.filename-div {
  display: block;
  font-size: xx-small;
  white-space: normal;
  word-wrap: break-word;
  overflow-wrap: break-word;
  line-height: normal;
  padding: 5px;
  position: relative;
}
.delete-icon {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0px;
  cursor: pointer;
  color: #504eca;
}
.menu-line {
  border-top: solid 1px rgba(0, 0, 0, 0.4);
  width: 80%;
  margin: auto;
  margin-bottom: 10px;
  padding-top: 10px;
  font-size: x-small;
  color: rgba(0, 0, 0, 0.4);
}
.file-icon {
  width: 75px;
  cursor: pointer;
  margin: 10px;
}
.button-detail-group {
  width: 40%;
  height: 50%;
}
.pdf-block {
  display: flex;
  flex-direction: column;
  width: 20%;
  align-items: center;
  font-weight: bold;
  font-size: xx-small;
  border: 0.5px solid gray;
  background-color: #999;
  color: white;
  height: 100%;
  border-radius: 10px;
  width: 100%;
  cursor: pointer;
  margin: 10px;
}
.files-list {
  display: flex;
  flex-direction: row;
  margin: auto;
}
.menu-project {
  max-height: 30vh;
  overflow: auto;
}
/*overflow detailing */

.custom-scrollbar {
  border-left: 1px solid #f1f1f1;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 5px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: #2db7db;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #2db7db;
  border-radius: 5px;
}

/* For Firefox */
.custom-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: #2db7db #f1f1f1; /* Handle and track color */
}
.project-name-main {
  position: fixed;
  top: 15%;
  left: 5%;
  z-index: 2;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 10px;
  color: #000;
  background-color: #f5f5f5;
}
.description-source {
  margin-left: 10px;
  font-size: xx-small;
}
